(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("moveFolderControllerFileStorage", moveFolderControllerFileStorage);
    function moveFolderControllerFileStorage($scope, $mdDialog, $filter, folders, folder) {
        $scope.controller = this;
        $scope.selectedNode = "";
        $scope.title = "MOVE_FOLDER";
		$scope.folders = $.grep(folders, function (f) {
			return f.path !== folder && f.access !== 2 &&
				f.path.indexOf("86bc2a5f-89d8-4e76-a3e8-c53cbebe565d") === -1 && 
				f.path.indexOf("PUBLIC_CHAT_FILES") === -1;
        });
        
        $scope.selectedFolder = JSON.parse(JSON.stringify($scope.folders[0]));
        if ($scope.selectedFolder.translatedName !== undefined)
            $scope.selectedFolder.translatedName = $scope.selectedFolder.translatedName.replaceAll("\u00A0", '');
        $scope.selectedFolder.name = $scope.selectedFolder.name.replaceAll("\u00A0", '');

        $scope.onFolderChanged = function (newVal) {
            $scope.selectedFolder = JSON.parse(JSON.stringify(newVal));
            if ($scope.selectedFolder.translatedName !== undefined)
                $scope.selectedFolder.translatedName = newVal.translatedName.replaceAll("\u00A0", '');
            $scope.selectedFolder.name = newVal.name.replaceAll("\u00A0", '');
        }

		for (var i = 0; i < $scope.folders.length; i++) {
			$scope.folders[i].displayPath = $filter("translate")("MY_FILES") + $scope.folders[i].path;
		}

        $scope.done = function () {
            $mdDialog.hide({ 'success': true, 'folder': $scope.selectedFolder.path, 'owner': $scope.selectedFolder.ownerEmailAddress });
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    };
})();