(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("fsNewFolderController", fsNewFolderController);

	function fsNewFolderController($scope, $mdDialog, $filter, folderInfo, folders) {
		$scope.treeData = [];
		$scope.treeController = {};
		$scope.treeData = $.extend(true, [], folderInfo.treeData);
		$scope.name = '';
		$scope.folders = folders;
		$scope.folders = $.grep($scope.folders, function (folder) {
			if (folder.path.indexOf("86bc2a5f-89d8-4e76-a3e8-c53cbebe565d") > -1) { return false; }
			if (folder.path.indexOf("PUBLIC_CHAT_FILES") > -1) { return false; }
		    return true;
		});
		//for (var i = 0; i < $scope.folders.length; ++i) {
		//	$scope.folders[i].name = $filter("translate")("MY_FILES") + $scope.folders[i].path;
		//}

		$scope.parentFolder = folderInfo.parentFolder;

		$scope.currentFolder = JSON.parse(JSON.stringify($scope.folders[0]));
		if ($scope.currentFolder.translatedName !== undefined)
			$scope.currentFolder.translatedName = $scope.currentFolder.translatedName.replaceAll("\u00A0", '');
		$scope.currentFolder.name = $scope.currentFolder.name.replaceAll("\u00A0", '');

		if (folderInfo.parentFolder.path.indexOf("86bc2a5f-89d8-4e76-a3e8-c53cbebe565d") > -1 || folderInfo.parentFolder.path.indexOf("PUBLIC_CHAT_FILES") > -1) {
		    $scope.currentFolder = folderInfo.parentFolder;
		    $scope.currentFolder.name = $filter("translate")("MY_FILES");
		} else {
		    $scope.currentFolder = folderInfo.parentFolder;
		}

		//$scope.onBranchChanged = function (branch) {
		//	$scope.currentFolderName = branch.data.id;
		//	$scope.currentFolder = branch.data.path;
		//}

		$scope.onFolderChanged = function (newVal) {
			$scope.currentFolder = JSON.parse(JSON.stringify(newVal));
			if ($scope.currentFolder.translatedName !== undefined)
				$scope.currentFolder.translatedName = newVal.translatedName.replaceAll("\u00A0", '');
			$scope.currentFolder.name = newVal.name.replaceAll("\u00A0", '');
		}

		$scope.save = function () {
			$mdDialog.hide({
				folderInfo: {
					folder: $scope.name,
					parentFolder: $scope.currentFolder.path
				}
			});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
